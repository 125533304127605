.participant{
  position: absolute;
  width: 200px;
  height: 200px;
}
.localParticipant {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}
.localParticipant h3 {
    margin-top: 10px;
    color: #fff;
    text-align: center;
}
.localParticipant video {
  height: 100%;
  display: block;
  margin: 0 auto;
  border-radius: 6px;
  transform: scaleX(-1);
} 
.user_info{
  width: 317px;
}
.user_info > div{
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  border-radius:50%;
  padding: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user_info > div[data-set=false]{
  border: 1px solid #202124;
}
.user_info > div > div[data-set=false]{
  border: 1px solid #202124;
}
.user_info > div > div {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box; 
  border-radius:50%;
  padding: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user_info img {
  width:100%;
  height: 100%;
  border-radius:50%;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing:border-box;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
.user_info > h3 {
  margin-top: 11px;
  color: #fff;
  text-align: center;
}
.remote_user_info_microphone_name {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.remote_user_info_microphone_name > h3 {
  color: #fff;
}
.remote_user_info_microphone_name > svg {
   margin-right: 30px;
   width: 50px;
   height: 25px;
}
.remote_user_info_microphone_atr > hr{
  width: 33px;
  position: relative;
  top: 16px;
  left:-1px;
  transform: rotate(60deg);
  border:1px solid #EB3E52;
}
.remote_user_info_microphone_atr {
  margin-right:20px;
}