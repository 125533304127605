.ControlCall:not(.timeWrapper) {
    width: 100%;
    height: 90px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #202124;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

.ControlCall:not(.timeWrapper)>div {
    margin-right: 60px;
    width: 45px;
    height: 45px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ControlCall:not(.timeWrapper)>div:hover:not(.timeWrapper) {
    transform: scale(1.1);
    opacity: 0.5;
}

.timeWrapper {
    cursor: default !important;
}

.border>hr {
    width: 33px;
    position: absolute;
    transform: rotate(60deg);
    border: 1px solid #EB3E52;
}

.border {
    border: 1px solid #FFFFFF;
}

.ControlCall_microphone[data-set=false] {
    border: 1px solid #EB3E52;
}

.ControlCall_video[data-set=false] {
    border: 1px solid #EB3E52;
}

.ControlCall_video>svg {
    width: 50px;
    height: 25px;
}

.ControlCall_microphone>svg {
    width: 50px;
    height: 25px;
}

.ControlCall_endCall {
    background-color: #EB3E52;
}

.ControlCall_endCall>svg {
    width: 25px;
    height: 15px;
}

.ControlCall_join {
    width: 70px !important;
    height: 45px !important;
    background-color: #202124;
    border-radius: 20px !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    color: white
}