.video_conference_header{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #202124;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding: 20px 30px;
    box-sizing: border-box;
}
.video_conference_header_identity{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.video_conference_header_identity > div{
    margin-right: 25px;
    color: white;
}
.video_conference_header_identity > img{
    width: 50px;
    height: 50px;
    border-radius: 30px;
    background-size: cover;
}