.videoconference_lobby {
   width: 100%;
   height: 100vh;
   background-color: #202124;
}

.permissionDeniedContainer {
   width: 100%;
   height: 100vh;
   background-color: #202124;
}

.lobby_join_content {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.lobby_join_content>video {
   height: 70%;
   width: 100%;
   transform: scaleX(-1);
   border-radius: 6px;
}