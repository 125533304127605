.room {
    width: 100%;
    height: 100%;
    background: #202124;
}
  .room button {
    position: absolute;
    top: 0;
    right: 20px;
  }
  .room > h3 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 1em;
  }
  .local_participant_largeSize {
    display: flex;
    justify-content: center;
    align-items:center;
    width: 100%;
    height: 100%;
  }
  .local_participant_smallSize{
    display: flex;
    justify-content: center;
    align-items:center;
    width: 300px;
    position: absolute;
    bottom: 115px;
    right: 40px;
  }
  .local_participant_smallSize .user_info{
    margin-bottom:0px;
    width: auto;
    height: auto;
  }
  .local_participant_smallSize .localParticipant{
    background: #202124;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
  }
  .local_participant_smallSize video{
    width: 100%;
  }
  .remote_participants {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }