.popup {
    position: absolute;
    width: 40vw;
    right: 50%;
    bottom: 0.52083333333vw;
    transform: translate(50%, 0vh);
    height: 12.3958333333vw;
    background: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 5px 5px 29px 6px rgba(0, 0, 0, 0.2);
    box-shadow: 5px 5px 29px 6px rgba(0, 0, 0, 0.2);
}

.message_title {
    font-size: 1.667vw;
    color: rgb(16, 29, 91);
    text-align: center;
    font-weight: 700;
    margin-bottom: 0.41666666666vw;
    margin-top: 2.08333333333vw;
    padding: 0 0.5vw;
}

.message_desc {
    color: rgb(16, 29, 91);
    font-size: 1vw;
    text-align: center;
    margin-bottom: 0.9375vw;
    font-weight: 500;
}

.message_time {
    font-size: 1.25vw;
}

.buttons_wrapper {
    display: flex;
    justify-content: end;
    border-top: 1px solid #f0f0f0;
    padding-top: 0.9375vw;
}

.buttons {
    width: 5.20833333333vw;
    background-color: #7854FF;
    border-radius: 4px;
    margin: 0 1.04166666667vw;
    cursor: pointer;
    font-size: 0.83333333333vw;
}

.cancel {
    background-color: #fff;
    color: rgb(235, 62, 82);
    border: 1px solid rgb(235, 62, 82) !important;
}

@media only screen and (max-width: 600px) {
    .popup {
        width: 100vw;
        right: 50%;
        bottom: 10px;
        transform: translate(50%, 0vh);
        height: 180px;
        padding: 0 20px;
    }

    .message_title {
        font-size: 22px;
        margin-bottom: 8px;
        margin-top: 15px;
    }

    .message_desc {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .message_time {
        font-size: 20px;
    }

    .buttons_wrapper {
        padding-top: 8px;
    }

    .buttons {
        width: 100px;
        margin: 0 20px;
        font-size: 12px;
    }
}